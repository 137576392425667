import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hello, I'm <span className="purple">Dor Porat</span>.
            <br />
            <br />
            With over 10 years of experience building digital products, I've helped SaaS companies
            grow beyond <span className="purple">$3M in annual recurring revenue</span>. My
            expertise combines technical skills with strategic growth, developing robust web
            applications while implementing SEO and PPC strategies to improve search rankings and
            online visibility.
            <br />
            <br />
            I specialize in full-stack development, including AI products, and I believe in
            transparent communication and collaboration. Whether you're a startup founder looking to
            scale or an established business seeking technical expertise, I offer a{" "}
            <span className="purple">complimentary strategy session</span> to discuss how we can
            achieve your goals. Let's talk about your vision and plan a path to exceptional results.
          </p>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Success in business is about creating value that lasts."
          </p>
          <footer className="blockquote-footer">Warren Buffett</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
