import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import {
  AiFillGithub,
  AiOutlineWhatsApp
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { Helmet } from "react-helmet";

// Import project images
import Sunjoy from "../../Assets/Projects/Sunjoy.png";
import Schumacher from "../../Assets/Projects/Schumacher.png";
import layla from "../../Assets/Projects/layla.png";
import Law from "../../Assets/Projects/Law.png";
import inceptionXr from "../../Assets/Projects/inceptionXr.png";
import tlv from "../../Assets/Projects/Tel-Aviv-Yaffo.png";
import siman from "../../Assets/Projects/siman-website-development.png";
import convertingdigital from "../../Assets/Projects/convertingdigital-development.png";
import folloapp from "../../Assets/Projects/folloapp.png";
import maaglim from "../../Assets/Projects/maaglim.png";
import cadesign from "../../Assets/Projects/cadesign.jpeg";
import firstmedia from "../../Assets/Projects/firstmedia.webp";
import hortica from "../../Assets/Projects/hortica.png";
import learn_german from "../../Assets/Projects/learn_german.png";
import mod from "../../Assets/Projects/mod.jpeg";
import loveme from "../../Assets/Projects/Loveme.png";
import c4 from "../../Assets/Projects/c4.jpeg";
import dk from "../../Assets/Projects/dk.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Helmet>
        <title>Projects | Dor Porat</title>
        <meta
          name="description"
          content="Explore the recent projects by Dor Porat, including full-stack development, AI products, SEO, and PPC services."
        />
        <meta
          name="keywords"
          content="projects, full-stack development, AI products, SEO, PPC, Dor Porat"
        />
      </Helmet>
      <Particle />
      <Container>
        <h1 className="project-heading">
          Recent <strong className="purple">Projects</strong>
        </h1>
        <p style={{ color: "white" }}>
          Below are some of the projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tlv}
              isBlog={false}
              title="Tel Aviv-Yafo Municipality"
              description="Developed a comprehensive web platform for the city's official website."
              link="https://www.tel-aviv.gov.il/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mod}
              isBlog={false}
              title="Israeli Ministry of Defense"
              description="Provided software development services for national defense projects."
              link="https://www.mod.gov.il/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={loveme}
              isBlog={false}
              title="LoveMe"
              description="Delivered software solutions to enhance user experience on the platform."
              link="https://www.loveme.co.il/en/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={c4}
              isBlog={false}
              title="C4 Systems"
              description="Provided full-stack software services for enterprise solutions."
              link="https://www.c4systems.co.il/en/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dk}
              isBlog={false}
              title="Deepkeep Ltd."
              description="Developed AI-based solutions and full-stack services."
              link="https://deepkeep.ai/home"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={firstmedia}
              isBlog={false}
              title="First Media"
              description="Implemented Python-based ETL operations using Google Cloud and AWS."
              link="https://first.media/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={maaglim}
              isBlog={false}
              title="Maaglim ERP System"
              description="Developed a complete ERP system for hours management using PHP and Laravel."
              link="https://www.maagalimds.co.il/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={folloapp}
              isBlog={false}
              title="Folloapp"
              description="Built an iOS and Android application using C# and React Native."
              link="https://cnaan.co.il/%D7%9B%D7%9C%D7%9C%D7%99/folloapp-%D7%9B%D7%9A-%D7%AA%D7%A0%D7%94%D7%9C%D7%95-%D7%90%D7%AA-%D7%94%D7%9B%D7%A1%D7%A3-%D7%95%D7%94%D7%91%D7%99%D7%98%D7%95%D7%97%D7%99%D7%9D-%D7%A9%D7%9C%D7%9B%D7%9D-%D7%95%D7%AA%D7%97%D7%A1/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cadesign}
              isBlog={false}
              title="Cadesign"
              description="Created Python models for CAD software and developed web solutions."
              link="https://cadesign64.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hortica}
              isBlog={false}
              title="Hortica"
              description="Handled Python development and designed AWS architecture."
              link="https://hortica.io/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={siman}
              isBlog={false}
              title="Siman Employment Program"
              description="Provided complete development and design services."
              link="https://siman.co.il"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={inceptionXr}
              isBlog={false}
              title="InceptionXR"
              description="Worked on PHP and JavaScript development for their platform."
              link="https://bookful.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={layla}
              isBlog={false}
              title="Layla Und Yom"
              description="Developed an e-commerce shop for a retail business."
              link="https://layla-yom.de"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Sunjoy}
              isBlog={false}
              title="Sunjoy"
              description="Provided web development services for their online presence."
              link="https://sunjoy.co.il"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Schumacher}
              isBlog={false}
              title="Schumacher Car Dealership"
              description="Developed a complete ERP system for dealership management."
              link="https://schumacher-ctc.de/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={convertingdigital}
              isBlog={false}
              title="Converting Digital"
              description="Offered web development and SEO services for a marketing agency."
              link="https://convertingdigital.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Law}
              isBlog={false}
              title="Law Office Website"
              description="Developed a professional website for a law firm."
              link="https://www.poratlaw.co.il"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={learn_german}
              isBlog={false}
              title="Private Tutor Website"
              description="Created a website for a private German language tutor."
              link="https://e-germanit.com/"
            />
          </Col>
        </Row>
        <Col md={12} className="home-about-social">
          <h1>Find Me On</h1>
          <p>
            Feel free to <span className="purple">connect</span> with me
          </p>
          <ul className="home-about-social-links">
            <li className="social-icons">
              <a
                href="https://github.com/dor-p"
                target="_blank"
                rel="noreferrer"
                className="icon-colour  home-social-icons"
              >
                <AiFillGithub />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.linkedin.com/in/dorp/"
                target="_blank"
                rel="noreferrer"
                className="icon-colour  home-social-icons"
              >
                <FaLinkedinIn />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://wa.me/+972545723256?text=Howdy"
                target="_blank"
                rel="noreferrer"
                className="icon-colour  home-social-icons"
              >
                <AiOutlineWhatsApp />
              </a>
            </li>
          </ul>
        </Col>
      </Container>
    </Container>
  );
}

export default Projects;
