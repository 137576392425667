import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import myImg from "../../Assets/dor_pic.jpg";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <section>
      <Helmet>
        <title>Dor Porat | Full-Stack Developer, AI Products, SEO & PPC Specialist</title>
        <meta
          name="description"
          content="I help SaaS founders scale their businesses with full-stack development, including AI products, and effective SEO & PPC strategies."
        />
        <meta
          name="keywords"
          content="full-stack development, AI products, SEO, PPC, Dor Porat, SaaS growth"
        />
      </Helmet>
      <Container
        fluid
        className="home-section"
        id="home"
        style={{
          paddingTop: "1rem",
          paddingBottom: "1rem",
          minHeight: "90vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Particle />
        <Container className="home-content" style={{ padding: "0 1rem" }}>
          <Row className="flex-column-reverse flex-md-row align-items-center">
            {/* Text Column */}
            <Col md={7} className="home-header text-center text-md-start">
              <h1 className="heading-name" style={{ fontSize: "1.5rem" }}>
                Hello, I'm <span className="purple">Dor Porat.</span>
              </h1>
              <p className="home-about-body" style={{ fontSize: "0.9rem", lineHeight: "1.4" }}>
                I help SaaS founders scale their businesses beyond{" "}
                <span className="purple">$3M+ ARR</span>. Combining technical expertise with
                effective SEO and PPC strategies, I turn promising products into market leaders.
                With a portfolio of over 50 successful projects in full-stack development,
                including AI products, I build scalable solutions that drive growth.
                <br />
                <br />
                I blend precise technical execution with proven SEO and PPC methods. Working with
                SaaS companies, I've helped turn visions into thriving businesses. Using Python,
                JavaScript, and modern web technologies, I build solid foundations that support
                rapid scaling. Beyond code, I lead distributed teams to ensure every project
                maintains the highest quality standards, accelerates time-to-market, and promotes
                sustainable growth.
              </p>
            </Col>
            {/* Image Column */}
            <Col md={5} className="mb-4 mb-md-0 d-flex justify-content-center">
              <img
                src={myImg}
                alt="profile"
                className="img-fluid rounded-circle"
                style={{ maxHeight: "300px", width: "auto" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Home;
